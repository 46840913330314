<style lang="less">
  .van-icon-search{
    color: #ED6A0C
  }
  .home .van-search__content{
    background: #f7f7f7;
  }
</style>
<template>
    <div class="gray-bg home" v-htmltit :data-title="sysOfficeInfo.name?sysOfficeInfo.name:'必订货'">
      <go-cart-btn></go-cart-btn>
      <skeleton v-show="!loading"></skeleton>
      <div v-show="loading" class="my-main">
        <div class="my-search-box">
          <div class="my-search-top">
            <van-search
              left-icon="search"
              color="red"
              shape="round"
              autofocus
              :disabled="true"
              placeholder="点击搜索"
              @click="handleClickSearch" />
          </div>
        </div>
        <div class="class-list">
          <class-list @closeSkeleton="closeSkeleton"></class-list>
        </div>
      </div>
      <my-footer></my-footer>
    </div>
</template>
<script>
import "./index.less"
import myFooter from "@/components/Footer"
import goCartBtn from "@/view/Public/goCartBtn"
import skeleton from "./component/skeleton"
import classList from "./component/classList"
export default {
  name:"index",
  components:{ skeleton ,goCartBtn, myFooter , classList},
  data(){
    return{
      sysOfficeInfo:{
          id:localStorage.getItem('sysOfficeId'),
          name:localStorage.getItem('sysOfficeName'),
          img:localStorage.getItem('sysOfficeImg'),
      },
      loading:false, //加载成功之前显示骨架页
    }
  },
  methods:{
    //点击搜索
    handleClickSearch(){
      this.$toast("暂未开放")
      return;
      this.$router.push({ name:"search" })
    },
    //关闭骨架屏
    closeSkeleton(v){
      this.loading = v
    }
  }
}
</script>
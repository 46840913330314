<template>
    <div style="padding: 0.5rem 0;background:#fff">
        <div class="skeleton-head">
            <div class="skeleton-head-search">
                <van-skeleton title-width="100%" title />
                <van-skeleton title-width="100%" title />
            </div>
            <div class="skeleton-head-type">
                <van-skeleton :row="1" row-width="2rem" />
                <van-skeleton :row="1" row-width="2rem" />
                <van-skeleton :row="1" row-width="2rem" />
                <van-skeleton :row="1" row-width="2rem" />
                <van-skeleton :row="1" row-width="2rem" />
            </div>
        </div>
        <div class="skeleton-list">
            <div class="skeleton-item" v-for="(item,index) in data" :key="index">
                <div>
                    <van-skeleton title  title-width="100%" />
                    <van-skeleton title  title-width="100%" />
                    <van-skeleton title  title-width="100%" />
                    <van-skeleton title  title-width="100%" />
                </div>
                <div style="margin-top:0.5rem"><van-skeleton title  title-width="100%" /></div>
            </div>
        </div>
    </div>
</template>
<script>
import "./index.less"
export default {
    name:"skeleton",
    data(){
        return{
            data:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
        }
    }
}
</script>
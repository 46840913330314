<template>
    <div class="home-class-list">
        <van-tabs color="#ED6A0C" v-model="tabActive"  :border="false" :ellipsis="false" @change="handleChangeTab">
            <van-tab :name="item.id" v-for="item in classList"  :key="item.id" :title="item.name">
                <div v-if="pinList.length == 0" class="isNull">暂无数据</div>
                <div class="pinListNot">
                    <template v-for="pinItem in pinList">
                        <div class="pinItem" @click="handleClickPin(pinItem)" :key="pinItem.id" v-if="pinItem.children.length == 0">
                            <van-image fit="contain"
                                class="img-box"
                                :src="getImgSizeJpg(pinItem.img,4.2,4.2)">
                                <template v-slot:error>
                                    <img style="width:4.2rem;height:4.2rem" :src="defaultImg" />
                                </template>
                                <!-- <template v-slot:loading>
                                    <img style="width:4.2rem;height:4.2rem" :src="defaultImg" />
                                </template> -->
                            </van-image>
                            <div class="child-title">{{pinItem.name}}</div>
                        </div>
                    </template>
                </div>
                <div class="pinList">
                    <template v-for="pinItem in pinList">
                        <div class="pinItem" v-if="pinItem.children.length > 0" :key="pinItem.id">
                            <div class="pinTit" @click="handleClickPin(pinItem)">{{pinItem.name}}</div>
                            <div class="pinChildList">
                                <div class="pinChildItem" @click="handleClickPin(pinItem,pinChildItem)" v-for="pinChildItem in pinItem.children" :key="pinChildItem.id">
                                    <van-image fit="contain"
                                        class="img-box"
                                        :src="getImgSizeJpg(pinChildItem.img,4.2,4.2)">
                                        <template v-slot:error>
                                            <img style="width:4.2rem;height:4.2rem" :src="defaultImg" />
                                        </template>
                                        <!-- <template v-slot:loading>
                                            <img style="width:4.2rem;height:4.2rem" :src="defaultImg" />
                                        </template> -->
                                    </van-image>
                                    <div class="child-title">{{pinChildItem.name}}</div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>
<script>
import './index.less'
import { category } from "@/apis/api"
import defaultImg from "@/assets/image/defaultClass.png"

export default {
    name:"class-list",
    data(){
        return{
            tabActive:"",
            defaultImg:defaultImg,
            classList:[],
            pinList:[],
            selectClass:{},
        }
    },
    activated(){
        let keepAliveArr = ['good-lists','cart']
        const beforeRouterName = this.beforeRouter.name;
        if (beforeRouterName &&  !keepAliveArr.includes(beforeRouterName)) {
            // 手动刷新页面
            this.getCategoryOne();
        }
    },
    mounted(){
        this.getCategoryOne();
    },
    methods:{
         //获取一级商品分类
        getCategoryOne(){
            let req = {
                pid:"",
                type:"0"
            }
            category(req).then(res=>{
                if(res.code === 0){
                    this.classList = res.res.data;
                    this.selectClass = res.res.data[0];
                    this.tabActive = res.res.data[0].id;
                    this.getCategoryChild(this.classList[0].id);
                }else{
                    this.$toast(res.msg)
                }
            })
        },
        //获取二级分类
        getCategoryChild(pid){
            let req = {
                pid:pid,
                type:"1"
            }
            const toast = this.$toast.loading({
                message: '加载中...',
                forbidClick: true
            });
            category(req).then(res=>{
                toast.clear();
                if(res.code === 0){
                    this.pinList = res.res.data
                    this.$emit("closeSkeleton",true)
                }else{
                    this.$toast(res.msg)
                }
            })
        },
        handleChangeTab(name,title){
           this.selectClass = {
               id:name,
               name:title
           }
           this.getCategoryChild(this.selectClass.id)
        },
        //点击品牌进入二级页面
        handleClickPin(selectItem,selectChildItem){
            // 先清除旧缓存
            this.$store.commit("clearSearchclassone")
            this.$store.commit("clearSearchClassTwo")
            this.$store.commit("clearSearchClassThree")
            // 缓存新数据
            this.$store.commit("setSearchClassOne",this.selectClass)
            this.$store.commit("setSearchClassTwo",selectItem)
            this.$store.commit("setSearchClassThree",selectChildItem)
            this.$router.push({
                name:"good-lists"
            })
        }
    }
}
</script>
<style scoped lang="less">
    .van-tabbar{
        box-shadow: -0.01rem -0.01rem 0.1rem  #f0f0f0;
    }
    .price_tip{
        position: fixed;
        bottom: 3.1rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: rgba(255, 255, 255, 1);
        background: rgba(255, 151, 106, 1);
        font-size: 0.7rem;
        padding: .2rem;
    }
    // .price_tip_icon{
    //     position: absolute;
    //     right: 1rem;
    //     width: 1rem;
    //     text-align: center;
    //     height: 1rem;
    //     line-height: 1rem;
    //     top: 50%;
    //     margin-top: -.5rem;
    // }
</style>
<template>
    <div>
        <div class="price_tip" v-if="isClose === false && showPriceTip">
            <div style="width:65%;text-align:right">您的余额已不足300，请及时充值</div>
            <div style="flex-shrink: 0;width:15%;font-weight: bold;" @click="goPriceDetail">查看明细</div>
            <van-icon style="flex-shrink: 0;width:15%;text-align:center" @click="closePriceTipShow" name="cross" size="14" />
        </div>
        <van-tabbar v-model="activeTab" active-color="#ED6A0C" :route="true" :replace="true">
            <van-tabbar-item to="/" icon="bag">订货</van-tabbar-item>
            <!-- <van-tabbar-item to="/photo-order" icon="photograph">拍单</van-tabbar-item> -->
            <van-tabbar-item v-if="sysOfficeId !== nongSysOfficeId" to="/photo-order" icon="photograph">拍单</van-tabbar-item>
            <van-tabbar-item v-if="noPay > 0" to="/order" :badge="noPay" icon="balance-list">订单</van-tabbar-item>
            <van-tabbar-item v-else to="/order" icon="balance-list">订单</van-tabbar-item>
            <van-tabbar-item to="/own" icon="manager">我的</van-tabbar-item>
        </van-tabbar>
    </div>
</template>
<script>
import global from '@/libs/global'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { GetUserBalance } from "@/apis/api"
export default {
    name:'my-footer',
    data(){
        return{
            activeTab: 0,
            noPay:0, // 未支付订单数量
            // 下边的数据就是为了农科尚品 一个死的100元 以后 这个就可以删掉了
            sysOfficeId:localStorage.getItem('sysOfficeId'),
            nongSysOfficeId:global.sysOfficeId,
            // 上边的数据就是为了农科尚品 一个死的100元 以后 这个就可以删掉了
            showPriceTip: false
        }
    },
    computed: {
    ...mapGetters(['noPayCount','isClose'])
    },
    mounted (){
        this.apiNoPayCount()
        this.getBalance()
    },
    watch: {
        noPayCount:{
            deep: true,
            immediate: true,
            handler(newVal, oldVal) {
                this.noPay = newVal
            }
        }
    },
    methods: {
        ...mapMutations(['setIsclose']),
        ...mapActions(['getNoPayCount']),
        // 获取余额
        getBalance(){
            let params = {
                customer_id: localStorage.getItem('customerId')
            }
            GetUserBalance(params).then(res=>{
                if(res.code == 0){
                    this.showPriceTip = res.res.balance < 300 ? true : false
                }else{
                    this.$toast(res.msg)
                }
            })
        },
        // 去明细页面
        goPriceDetail(){
            this.$router.push({ name:'price-detail' })
        },
        closePriceTipShow(){
            this.showPriceTip = false
            this.setIsclose(true)
        },
        apiNoPayCount (){
            this.getNoPayCount().then(res => {
                this.noPay = this.noPayCount
            })
        }
    }
}
</script>